@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);

html,
body {
  height: 100%;
}

.PageNotFound {
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 800px) {
  .PageNotFound {
    display: grid;
    grid-template-columns: 500px auto;
    grid-gap: 2em;
    align-items: center;
    justify-content: center;
    margin: 3em 0;
  }
}
.PageNotFound-image {
  max-width: 100%;
}
.ContactPage-backLink {
  display: inline-block;
  margin-bottom: 1em;
}
.ContactPage-resultBlock {
  max-width: 950px;
}
.DepartmentPreferences-heading {
  display: inline-block;
  margin: 1em 0.5em 0.2em 0;
}
.DepartmentPreferences-adminTable {
  margin-bottom: 2em;
}
.DepartmentPreferences-adminTable-row {
  border-bottom: 1px solid #99a9bb;
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  padding: 1em 0;
}
.DepartmentPreferences-adminTable-row-name {
  font-weight: 700;
}
.Department {
  max-width: 950px;
}
.Department-block {
  border: 1px solid #99a9bb;
  border-radius: 3px;
  margin: 1.5em 0 2.5em;
  background: #fff;
  position: relative;
}
.Department-block-overview {
  padding: 2em;
}
.Department-block-overview-header {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 2em;
}
.Department-block-overview-header-title {
  margin: 0;
}
.Department-block-overview-description {
  margin-top: 1em;
  max-width: 42em;
}
.Department-block-contactInfo {
  background: #e6eaee;
  padding: 2em;
}
.Index-departmentHeader {
  display: grid;
  grid-gap: 0.75em;
  border-bottom: 1px solid #99a9bb;
  margin-bottom: 1.25em;
  padding-bottom: 0.75em;
}
@media screen and (min-width: 601px) {
  .Index-departmentHeader {
    grid-template-columns: 1fr auto;
  }
}
@media screen and (max-width: 600px) {
  .Index-departmentHeader {
    grid-template-rows: auto auto;
  }
}
.Index-departmentHeader-heading {
  font-weight: 700;
  font-size: 1.2em;
}
.Index-departmentHeader-filter {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1em;
  height: 2em;
}
.Index-departmentHeader-filter-label {
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 0.8em;
  color: #8094ab;
  font-weight: 700;
  align-self: center;
}
.Index-departmentHeader-filter-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background-color: inherit;
  border-radius: 3px;
  outline: none;
  position: relative;
  border: 1px solid #99a9bb;
  padding: 0 0.6em;
}
.Index-departmentHeader-filter-input::-webkit-input-placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.Index-departmentHeader-filter-input:-ms-input-placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.Index-departmentHeader-filter-input::-ms-input-placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.Index-departmentHeader-filter-input::placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.Index-departmentList {
  -webkit-column-count: auto;
  column-count: auto;
  -webkit-column-width: 16em;
  column-width: 16em;
  columns: 16em;
  -webkit-column-gap: 1.5em;
  column-gap: 1.5em;
  font-size: 12px;
  line-height: 175%;
}
.Index-departmentList-department {
  padding-left: 1.2em;
  position: relative;
}
.Index-departmentList-department:before {
  content: "\25E6";
  position: absolute;
  left: 0;
}
.MyPreferences-heading {
  display: inline-block;
  margin: 1em 0.5em 0.2em 0;
}
.MyPreferences-assistant {
  margin-top: 1.3em;
}
.MyPreferences-assistant-label {
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 0.95em;
}
.MyPreferences-assistant-search-results {
  background-color: #fff;
  border: 1px solid #99a9bb;
}
.MyPreferences-assistant-search-results-row {
  display: grid;
  border-bottom: 1px solid #99a9bb;
  padding: 0.8em;
  grid-template-columns: 1fr auto;
}
.MyPreferences-assistant-search-results-row:last-of-type {
  border-bottom: none;
}
.MyPreferences-assistant-search-results-row-result {
  display: grid;
}
@media screen and (min-width: 700px) {
  .MyPreferences-assistant-search-results-row-result {
    grid-template-columns: repeat(3, 1fr);
  }
}
.MyPreferences-assistant-search-results-row-result-name {
  font-weight: 700;
}
.MyPreferences-assistant-search-results-row-result-title {
  font-style: italic;
}
.MyPreferences-hideStep3 {
  background-color: #edd480;
  padding: 1em;
  font-style: italic;
  max-width: 500px;
  margin-top: 1em;
}
.MyPreferences-hideStep3-link {
  color: #002856;
}
.MyPreferences-departmentInfo {
  border: 1px solid #99a9bb;
  background-color: #fff;
  padding: 0.5em 2em 2em;
  margin-top: 2em;
}
.MyPreferences-departmentInfo-link {
  font-size: 1.2em;
  display: block;
  margin: 0.5em 0;
}
.PreferencesAdmin-heading {
  display: inline-block;
  margin: 1em 0.5em 0.2em 0;
}
.PreferencesAdmin-actions {
  border: 1px solid #99a9bb;
  max-width: 500px;
  padding: 1.5em;
  margin-top: 2em;
}
.PreferencesAdmin-actions-label {
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 0.95em;
  margin-bottom: 1em;
}
.Results {
  max-width: 950px;
}
.Results-header {
  display: grid;
  align-items: baseline;
  margin-bottom: 1em;
}
@media screen and (max-width: 600px) {
  .Results-header {
    grid-template-rows: auto auto;
  }
}
@media screen and (min-width: 601px) {
  .Results-header {
    grid-template-columns: 1fr auto;
    grid-gap: 2em;
  }
}
.Results-header-title {
  margin: 0;
  font-size: 1.4em;
}
.Results-results {
  margin: 1.5em 0;
}
.StudentPreferences-heading {
  display: inline-block;
  margin: 1em 0.5em 0.2em 0;
}
.StudentPreferences-majorMinorOptions {
  margin: 2em 0;
}
.StudentPreferences-majorMinorOptions-label {
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 0.95em;
}
* {
  box-sizing: border-box;
}
html {
  font-size: 14px;
}
@media screen and (max-width: 500px) {
  html {
    font-size: 13px;
  }
}
@media screen and (max-width: 450px) {
  html {
    font-size: 12px;
  }
}
body {
  margin: 0;
  font-family: Open Sans, sans-serif;
  color: #002856;
}
a {
  color: #0091b3;
}
hr {
  margin: 1.5em 0;
  border: none;
  border-top: 1px solid #99a9bb;
}
.Layout {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
  background: #fff;
}
.Layout-nonFooter {
  position: relative;
  padding-bottom: 3em;
}
@media screen and (min-width: 751px) {
  .Layout-nonFooter:before {
    content: "";
    display: block;
    background: #daa900;
    width: 5px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
}
.caption {
  font-style: italic;
  color: #8094ab;
}
.PageWrapper {
  position: relative;
  max-width: 1500px;
  width: 90%;
  margin: 0 auto;
}
.WatermarkSeal {
  position: fixed;
  right: 6vh;
  bottom: 6vh;
  width: 88vh;
  height: 88vh;
  pointer-events: none;
  opacity: 0.04;
}
.WatermarkSeal img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 700px) {
  .WatermarkSeal {
    right: -10vw;
    bottom: -10vw;
    width: 88vw;
    height: 88vw;
  }
}
.Header {
  position: relative;
  padding: 2em 0;
}
@media screen and (min-width: 751px) {
  .Header:before {
    content: "";
    display: block;
    background: #daa900;
    width: 5px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
}
@media screen and (max-width: 750px) {
  .Header {
    padding-top: 1em;
    border-top: 5px solid #daa900;
  }
}
.Header-content {
  display: grid;
}
@media screen and (min-width: 751px) {
  .Header-content {
    grid-template-columns: auto 1fr;
    align-items: center;
  }
}
@media screen and (max-width: 750px) {
  .Header-content {
    grid-template-rows: auto auto;
    grid-gap: 2em;
  }
}
.Header-masthead {
  display: grid;
  align-items: center;
  justify-items: center;
  text-decoration: none;
  color: inherit;
}
@media screen and (max-width: 750px) {
  .Header-masthead {
    grid-row: 2/3;
  }
}
@media screen and (min-width: 501px) {
  .Header-masthead {
    grid-template-columns: auto 1fr;
    grid-gap: 2em;
  }
  .centerOnDesktop {
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  .Header-masthead {
    grid-template-rows: auto auto;
    grid-gap: 1em;
  }
}
.Header-masthead-link {
  text-decoration: none;
}
.Header-masthead-title {
  font-size: inherit;
  text-transform: uppercase;
  margin: 0;
  text-decoration: none;
}
@media screen and (min-width: 501px) {
  .Header-masthead-title {
    justify-self: start;
  }
}
.Header-masthead-title-heading-firstLine {
  font-size: 10px;
  letter-spacing: 0.2em;
  color: #002856;
  font-weight: 400;
}
.Header-masthead-title-heading-lastLine {
  font-weight: 700;
  font-size: 37px;
  letter-spacing: 0.1em;
  text-indent: -0.1em;
  color: #daa900;
  line-height: 120%;
}
.Header-links {
  line-height: 175%;
}
@media screen and (max-width: 750px) {
  .Header-links {
    grid-row: 1/2;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 1.5em;
    text-align: right;
    border-bottom: 1px solid #99a9bb;
    padding-bottom: 0.5em;
  }
}
@media screen and (min-width: 751px) {
  .Header-links {
    justify-self: end;
  }
}
.Header-links-signInOut {
  margin-right: 1em;
}
.EditButton {
  text-transform: uppercase;
  letter-spacing: 0.06em;
  text-indent: -0.06em;
  font-size: 0.8em;
  text-decoration: none;
  color: #002856;
  border: 1px solid #daa900;
  padding: 0 0.8em;
  height: 2em;
  border-radius: 1em;
  cursor: pointer;
  white-space: nowrap;
  background-color: #fff;
  display: inline-flex;
  align-items: center;
}
.EditButton--tab {
  border-bottom: none;
  border-radius: 1em 1em 0 0;
}
.Footer {
  position: relative;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
.Footer-faqBanner {
  background-color: #0091b3;
  color: #fff;
  text-decoration: none;
  padding: 1em 0;
  display: block;
  font-weight: 700;
  font-size: 1.2em;
  text-align: center;
}
.Footer-content {
  background: #002856;
  color: #fff;
  padding: 4em 0 6em;
}
.Footer-disclaimer {
  font-style: italic;
  line-height: 160%;
}
.Footer-disclaimer-link {
  color: #fff;
}
/* .Footer-disclaimer {
  max-width: 44em;
} */
.Footer-legal {
  font-style: italic;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
  line-height: 160%;
}
.Footer-legal-item:not(:last-of-type):after {
  content: "|";
  display: inline-block;
  margin: 0 0.7em;
}
.Footer-legal-link {
  color: #fff;
  text-decoration: none;
}
.Footer-legal-link:hover {
  text-decoration: underline;
}
// .SearchForm {
//   margin-bottom: 3em;
//   display: grid;
//   max-width: 700px;
// }
// @media screen and (min-width: 526px) {
//   .SearchForm {
//     grid-template-columns: 1fr auto;
//     height: 3em;
//     grid-gap: 1em;
//   }
// }
// @media screen and (max-width: 525px) {
//   .SearchForm {
//     grid-template-rows: 1fr 1fr;
//     height: 6.5em;
//     grid-gap: 0.5em;
//   }
// }
// .SearchForm-input {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   font-size: inherit;
//   font-family: inherit;
//   color: inherit;
//   background-color: inherit;
//   border-radius: 3px;
//   outline: none;
//   position: relative;
//   border: 1px solid #99a9bb;
//   padding: 0.8em;
// }
// .SearchForm-input::-webkit-input-placeholder {
//   color: rgba(0, 40, 86, 0.3);
//   font-style: italic;
// }
// .SearchForm-input:-ms-input-placeholder {
//   color: rgba(0, 40, 86, 0.3);
//   font-style: italic;
// }
// .SearchForm-input::-ms-input-placeholder {
//   color: rgba(0, 40, 86, 0.3);
//   font-style: italic;
// }
// .SearchForm-input::placeholder {
//   color: rgba(0, 40, 86, 0.3);
//   font-style: italic;
// }
// .SearchForm-submit {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   font-size: inherit;
//   font-family: inherit;
//   color: inherit;
//   background-color: inherit;
//   border-radius: 3px;
//   outline: none;
//   border: none;
//   position: relative;
//   background: #002856;
//   color: #fff;
//   text-transform: uppercase;
//   letter-spacing: 0.15em;
//   text-indent: 0.15em;
//   padding: 0 2em;
//   cursor: pointer;
// }
// .SearchForm-submit:hover {
//   background-color: #0091b3;
// }
.ResultBlock-editButton {
  text-align: right;
}
.ResultBlock {
  position: relative;
  border: 1px solid #99a9bb;
  background: #fff;
  border-radius: 3px;
  margin-bottom: 1em;
  display: grid;
  align-items: start;
}
@media screen and (max-width: 500px) {
  .ResultBlock {
    grid-template-rows: auto auto;
  }
}
@media screen and (min-width: 501px) {
  .ResultBlock {
    grid-template-columns: auto 1fr;
  }
}
.ResultBlock-hiddenBanner {
  background-color: #edd480;
  border-radius: 3px 3px 0 0;
  padding: 0.6em 1em;
  font-style: italic;
}
.ResultBlock-hiddenBanner a {
  color: #002856;
  text-decoration: underline;
}
.ResultBlock-avatar {
  align-self: stretch;
  overflow: hidden;
  position: relative;
  background: #e6eaee;
}

// .panel
// {
//     /* position: relative;
//     border: 1px solid #99a9bb;
//     background: #fff;
//     border-radius: 3px;
//     margin-bottom: 1em;
//     display: grid;
//     align-items: start; */
// }

// .panel-collapse, .collapse
// {
//  overflow: hidden;
// }
.faq-group {
  background-color: #fff;
}
// .panel-collapse, .in, .collapse
// {
//   max-height: 1000px;
//   transition: max-height 0.5s;
//   transition-delay: 0s;
// }

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.ResultBlock--expanded .ResultBlock-info {
  max-height: 1000px;
  transition: max-height 2s;
}
.ResultBlock--expanded .ResultBlock-avatar-image,
.ResultBlock--expanded .ResultBlock-avatar-initials {
  width: 12em;
  height: 12em;
  transition-delay: 0s;
}
.ResultBlock--expanded .ResultBlock-info-descriptor {
  display: none;
}
.ResultBlock--expanded .ResultBlock-info-optional {
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.6s 0.6s;
}
@media screen and (min-width: 800px) {
  .ResultBlock--expanded .ResultBlock-info-persistent-nameDescription {
    grid-template-columns: 1fr auto;
  }
}
.ResultBlock-avatar-blur {
  position: absolute;
  background-position: 50%;
  background-size: cover;
  -webkit-filter: blur(20px);
  filter: blur(20px);
  transform: scale(1.2);
  z-index: 1;
  opacity: 0.6;
  width: 100%;
  height: 100%;
}
.ResultBlock-avatar-image {
  display: block;
  background-position: 50%;
  background-size: cover;
  position: relative;
  z-index: 2;
}
.ResultBlock-avatar-image,
.ResultBlock-avatar-initials {
  height: 6em;
  width: 6em;
  transition: all 0.5s;
  transition-delay: 0.2s;
}
.ResultBlock-avatar-initials {
  display: block;
  background: rgba(0, 40, 86, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
.ResultBlock-avatar-initials-initials {
  font-size: 2em;
  opacity: 0.4;
}
.ResultBlock-info {
  max-height: 6em;
  transition: max-height 0.5s;
}
.ResultBlock-info-persistent {
  min-height: 6em;
  align-self: center;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1em;
  align-items: center;
  padding: 1em 2em;
}
.ResultBlock-info-persistent:hover {
  background: #fdfdfd;
}
@media screen and (min-width: 800px) {
  .ResultBlock-info-persistent-nameDescription {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 1em;
    align-items: center;
  }
  .UCMLink {
    display: none;
  }
}
.ResultBlock-info-name {
  font-weight: 700;
  font-size: 1.15em;
}
.ResultBlock-info-descriptor {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}
@media screen and (max-width: 500px) {
  .ResultBlock-info-descriptor {
    width: calc(90vw - 8em);
  }
}
@media screen and (min-width: 501px) {
  .ResultBlock-info-descriptor {
    width: calc(90vw - 13em);
  }
}
@media screen and (min-width: 800px) {
  .ResultBlock-info-descriptor {
    width: calc(90vw - 30em);
  }
}
.ResultBlock-info-title {
  font-style: italic;
  margin-bottom: 0.4em;
}
.ResultBlock-info-office {
  font-weight: 700;
  /*margin-bottom: 1em;*/
}
.ResultBlock-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ResultBlock-info-general {
  padding: 0 2em 1em;
}
.ResultBlock-contactTable {
  margin-top: 1em;
}
.ResultBlock-assistant {
  border-top: 1px solid #99a9bb;
  margin-top: 1em;
  padding-top: 1em;
}
.ResultBlock-assistant-title {
  letter-spacing: 0.15em;
  font-weight: 700;
  font-size: 0.8em;
  text-transform: uppercase;
  margin-bottom: 1em;
}
@media screen and (min-width: 800px) {
  .ResultBlock-assistant-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
  }
}
.ResultBlock-assistant-info-name {
  font-weight: 700;
  margin-bottom: 0.4em;
  font-size: 1.15em;
}
.ResultBlock-assistant-info-location {
  font-style: italic;
}
.ResultBlock-info-actions {
  display: grid;
  grid-row-gap: 2px;
}
@media screen and (min-width: 700px) {
  .ResultBlock-info-actions {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2px;
  }
}
.ResultBlock-info-actions-permalink,
.ResultBlock-info-actions-vCard {
  background: rgba(0, 145, 179, 0.1);
  padding: 0 2em;
  text-decoration: none;
  display: grid;
  grid-template-columns: 1fr auto;
  min-height: 3em;
  align-items: center;
}
.ResultBlock-info-actions-permalink:hover,
.ResultBlock-info-actions-vCard:hover {
  background: rgba(0, 145, 179, 0.2);
}
.ResultBlock-info-optional {
  opacity: 0;
  transition: opacity 0.25s;
  pointer-events: none;
}
.TableRow {
  margin-bottom: 0.5em;
}
@media screen and (min-width: 550px) {
  .TableRow-nonedit {
    grid-template-columns: 12em 1fr !important;
  }

  .TableRow {
    display: grid;
    grid-template-columns: 10em 1fr;
    align-items: baseline;
  }
  .TableRow-assignment {
    display: grid;
    grid-template-columns: 9em 1fr;
    align-items: baseline;
  }
}
.TableRow-label {
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 0.8em;
  color: #8094ab;
  font-weight: 700;
}
.TableRow-value {
  overflow: hidden;
  text-overflow: ellipsis;
}
.SignInFlag {
  background: rgba(218, 169, 0, 0.2);
  display: inline-block;
  padding: 0.5em 1em;
  border-left: 6px solid #daa900;
  font-style: italic;
  color: inherit;
  text-decoration: none;
}
.SignInFlag:hover {
  background-color: rgba(218, 169, 0, 0.3);
}
.nohrefstyle,
.nohrefstyle:active,
.nohrefstyle:link {
  text-decoration: none;
  color: #002856;
}
.pointer {
  cursor: pointer;
}
.LoadingBlock {
  position: relative;
  min-height: 33vh;
}
.LoadingBlock-spinner {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.LoadingBlock-spinner div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #002856;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.LoadingBlock-spinner div:first-child {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.LoadingBlock-spinner div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.LoadingBlock-spinner div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.LoadingBlock-spinner div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0);
  }
  to {
    transform: translate(24px);
  }
}
.InputLabelPair {
  margin: 1.3em 0 0;
}
.InputLabelPair-label {
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 0.95em;
  margin-bottom: 0.6em;
  display: block;
}
.InputLabelPair-textInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background-color: inherit;
  border-radius: 3px;
  outline: none;
  position: relative;
  border: 1px solid #99a9bb;
  padding: 0.8em;
  background-color: #e6eaee;
  height: 3em;
  width: 100%;
}
.InputLabelPair-textInput::-webkit-input-placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.InputLabelPair-textInput:-ms-input-placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.InputLabelPair-textInput::-ms-input-placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.InputLabelPair-textInput::placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.InputLabelPair-selectInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background-color: inherit;
  border-radius: 3px;
  outline: none;
  position: relative;
  border: 1px solid #99a9bb;
  padding: 0.8em;
  background-color: #e6eaee;
  height: 3em;
  width: 100%;
  background-image: url(./components/Content/images/select-arrow.png);
  background-repeat: no-repeat;
  background-position: right 0.8em center;
  background-size: 1em 1em;
}
.InputLabelPair-selectInput::-webkit-input-placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.InputLabelPair-selectInput:-ms-input-placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.InputLabelPair-selectInput::-ms-input-placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.InputLabelPair-selectInput::placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.InputLabelPair-textareaInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background-color: inherit;
  border-radius: 3px;
  outline: none;
  position: relative;
  border: 1px solid #99a9bb;
  padding: 0.8em;
  background-color: #e6eaee;
  width: 100%;
}
.InputLabelPair-textareaInput::-webkit-input-placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.InputLabelPair-textareaInput:-ms-input-placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.InputLabelPair-textareaInput::-ms-input-placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.InputLabelPair-textareaInput::placeholder {
  color: rgba(0, 40, 86, 0.3);
  font-style: italic;
}
.InputLabelPair-error {
  background-color: #ff9291;
  font-style: italic;
  padding: 0.25em 0.5em;
  border-radius: 0 0 3px 3px;
}
.FormActions {
  margin: 3em 0 1em;
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1em;
}
.Button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background-color: inherit;
  border-radius: 3px;
  outline: none;
  border: none;
  position: relative;
  height: 3em;
  padding: 0 1.4em;
  line-height: 120%;
  cursor: pointer;
}
.Button--primary {
  background-color: #002856;
  color: #fff;
}
.Button--primary:hover {
  background-color: #0d335e;
}
.Button--secondary {
  color: #002856;
  border: 1px solid #002856;
}
.Button--secondary:hover {
  background-color: #f7f9fa;
}
.NonEditableInfoBox {
  padding: 1.5em;
  box-shadow: 0 2px 20px rgba(0, 40, 86, 0.25);
  background: #fff;
  max-width: 700px;
  margin: 3em 0;
}
.NonEditableInfoBox-label {
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 0.95em;
  margin-bottom: 1.5em;
}
@media screen and (min-width: 1000px) {
  .AdminPreferencesLayout {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 4em;
    align-items: start;
  }
}
.AdminPreferencesLayout-adminInfo,
.AdminPreferencesLayout-personalInfo {
  max-width: 800px;
}
@media screen and (min-width: 420px) {
  .MultiInputRow {
    display: grid;
    grid-column-gap: 1em;
    align-items: end;
  }
}
.MultiInputRow--2inputs {
  grid-template-columns: 1fr 1fr;
}
.MultiInputRow--3inputs {
  grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (min-width: 500px) {
  .PhotoPreferences {
    display: grid;
    grid-template-columns: 10em 1fr;
    grid-column-gap: 2em;
  }
}
.PhotoPreferences-image {
  width: 10em;
  height: 10em;
  margin-bottom: 1em;
  overflow: hidden;
  position: relative;
  background: #e6eaee;
  background-position: 50%;
  background-size: cover;
}
.PhotoPreferences-edit-heading {
  display: flex;
  align-items: baseline;
}
.PhotoPreferences-edit-heading-label {
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 0.95em;
  margin-bottom: 1em;
}
.PhotoPreferences-edit-heading-link--expanded
  .PhotoPreferences-edit-heading-link-icon {
  transform: rotate(180deg);
}
.PhotoPreferences-edit-heading-link-icon {
  display: inline-block;
  margin-left: 0.3em;
  transform-origin: 50% 55%;
}
.PhotoPreferences-edit-heading-link {
  margin-left: 0.3em;
}
.PhotoPreferences-edit-imageGuidelines {
  margin-bottom: 1em;
  max-width: 500px;
}
.PhotoPreferences-edit-upload {
  margin-bottom: 1em;
  border: 1px solid #99a9bb;
  display: inline-block;
  padding: 2em;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  min-width: 16em;
  text-align: center;
}
.PhotoPreferences-edit-upload-input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.PhotoPreferences-edit-error {
  background-color: #ff9291;
  margin-bottom: 1em;
  padding: 1em;
  max-width: 500px;
  font-style: italic;
}
.PhotoPreferences-edit-error-link {
  color: #002856;
}
.Checkbox {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1.3em 1fr;
  grid-column-gap: 0.6em;
  align-items: center;
  margin-bottom: 1em;
}
.Checkbox-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background-color: inherit;
  border-radius: 3px;
  outline: none;
  position: relative;
  background-color: #e6eaee;
  border: 1px solid #99a9bb;
  width: 18px;
  height: 18px;
  margin: 0;
  cursor: pointer;
}
.Checkbox-input:checked {
  background-image: url(./components/Content/images/checkmark.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
}
.Error {
  background-color: #ff9291;
  margin: 1em 0;
  padding: 1em;
  font-style: italic;
}
.Error a {
  color: #002856;
}
.AssistantRow {
  border-bottom: 1px solid #99a9bb;
  padding: 1em 0 0.4em;
  display: grid;
  grid-row-gap: 0.5em;
}
@media screen and (min-width: 700px) {
  .AssistantRow {
    grid-template-columns: 1fr 1fr auto;
    grid-column-gap: 2em;
  }
}
.AssistantRow-person-name {
  font-weight: 700;
  margin-bottom: 0.6em;
}
.AssistantRow-person-title {
  font-style: italic;
}
.AssistantRow-remove {
  text-decoration: underline;
}
.RadioButton {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1.3em 1fr;
  grid-column-gap: 0.6em;
  align-items: center;
  margin-bottom: 0.6em;
}
.RadioButton-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background-color: inherit;
  border-radius: 3px;
  outline: none;
  position: relative;
  background-color: #e6eaee;
  border: 1px solid #99a9bb;
  width: 18px;
  height: 18px;
  margin: 0;
  border-radius: 9px;
  cursor: pointer;
}
.RadioButton-input:checked:before {
  content: "";
  position: absolute;
  height: 12px;
  width: 12px;
  top: 2px;
  left: 2px;
  border-radius: 7px;
  background-color: #002856;
}
@keyframes slideout {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
.SuccessState {
  background-color: rgba(0, 145, 179, 0.8);
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0.8em;
  z-index: 2;
  animation: slideout 0.4s ease-in-out 3s 1 normal both;
}

// .animate-repeat {
//   -webkit-transition: 0.5s ease-in-out all;
//   transition: 0.5s ease-in-out all;

//   /* transform: scale(1.2); */
//   z-index: 1;
//   /* opacity: 0.6; */
// }
// .animate-repeat.ng-move,
// .animate-repeat.ng-enter,
// .animate-repeat.ng-leave {
//     -webkit-transition:all ease-in-out 0.5s;
//     transition:all ease-in-out 0.5s;
//     -webkit-filter: blur(1px);
//   filter: blur(1px);
// }

// .animate-repeat.ng-leave.ng-leave-active,
// .animate-repeat.ng-move,
// .animate-repeat.ng-enter {
//     opacity:0;
//     max-height:0;
// }

// .animate-repeat.ng-leave,
// .animate-repeat.ng-move.ng-move-active,
// .animate-repeat.ng-enter.ng-enter-active {
//     opacity:1;
//     max-height:40px;
// }

.hideImage {
  z-index: -99999;
  position: absolute;
  top: -999px;
}
.departmentList:hover {
  text-decoration: underline;
  /* overflow: visible;
  white-space: pre-wrap; */
}
.departmentList {
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  display: inline-block;
  width: 100%;
  /* overflow: hidden; */
  text-decoration: none;
}
.Index-departmentList-department {
  padding-left: 1.2em;
  position: relative;
  text-decoration: none;
}
/* .grecaptcha-badge { visibility: hidden; } */
.Index-departmentList-department:before {
  content: "\25E6";
  position: absolute;
  left: 0;
}

.emailImage {
  /* width:180px;  */
  display: unset;
}

.emailImageAnchor {
  /* width:180px;  */
  display: unset;
  background-color: unset;
  border: unset;
}

/* .ResultBlock-info-descriptor
{
  text-transform: capitalize;
} */

.accClosedDept {
  margin-top: 5px;
  /* text-transform: capitalize; */
  font-weight: bold;
}

.italic { font-style: italic; }

.workingTitle {
  text-transform: capitalize;
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footerLinks {
  color: #fff;
  text-decoration: none;
}
.footerLinks:hover {
  text-decoration: underline;
}

.faq {
  margin-bottom: 15px;
}

.faqlink {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 7px;
  font-size: 14px;
  line-height: 0.8;
  border-radius: 4px;
  font-style: normal;
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
  text-decoration: none;
  background-color: #daa900 !important;
  border-radius: 20px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 1px;
  margin-right: 5px;
  margin-left: 10px;
}

.faqlinks {
  font-size: 16px;
}

@media screen and (min-width: 800px) {
  .hideOnDesktop {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .hideOnMobile {
    display: none;
  }
  .faqHeader {
    grid-template-columns: 1fr !important;
  }
}

.myheader {
  text-align: left;
  padding: 0;
  background-color: #f6f6f5;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto 1fr;
  cursor: pointer;
  font-weight: 700;
  color: #fff;
  padding: 0;
  // margin: 16px 0 40px;
  box-sizing: border-box;
  position: relative;
  /* display: table;
  table-layout: fixed; */
  height: 100%;
  width: 100%;
}
.myheaderIcon {
  background-color: #0091b3;
  border: 0;
  position: relative;
  text-align: center;
  display: block;
  width: 48px;
  /* height: 48px; */
  height: 100%;
  line-height: 48px;
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 48px;
}
.myheaderText {
  /* display: table-cell; */
  color: #616161;
  text-align: left;
  margin: auto 30px;
  font-size: 16px;
}
.answer {
  text-align: justify;
  font-size: 15px;
}
.answerTransition {
  min-height: 20px;
  display: block;
}
.pageLayout {
  margin: 20px auto !important;
}

.imglist {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 50%;
  margin: 20px auto 20px 10%;
  display: block;
}
@media screen and (max-width: 700px) {
  .imglist {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 100%;
    margin: 1px;
    display: block;
  }
}
.backtoHome {
  float: left;
  font-size: 14px;
  font-weight: normal;
}
.faqHeader {
  display: grid;
  grid-gap: 0.75em;
  border-bottom: 1px solid rgba(0, 40, 86, 0.4);
  margin-bottom: 1.25em;
  padding-bottom: 0.75em;
  grid-template-columns: 1fr 1fr 1fr;
}
.faqHeader-heading {
  font-weight: 700;
  font-size: 1.2em;
}

.LoadingBlock-photo {
  position: relative;
  min-height: 17vh;
}

.LoadingBlock-spinner-photo {
  left: 19%;
}

.photoguide > div {
  display: grid;
  grid-template-columns: 87px 1fr;
  column-gap: 1em;
  text-align: justify;
}

.MyPreferences-hideStep4 {
  background-color: #ff9291;
  padding: 1em;
  font-style: italic;
  max-width: 500px;
  margin-top: 1em;
}
.MyPreferences-hideStep4-link {
  color: #002856;
}

.headshot-box {
  background-color: #f0f9fb;
  padding: 1em;
  max-width: 500px;
  margin-top: 1em;
}

fieldset {
  border: 1px solid #99a9bb;
}

legend {
  padding: 10px;
  font-weight: 700;
  font-size: 1.15em;
  color: #002856;
}

.tag-parent {
  display: grid;
  grid-template-columns: 13px auto;
  margin-bottom: 10px;
}
.tag {
  width: 5px;
  /*background-color: #8094ab;*/
  background-color: #ccd4dd;
}
.tag-primary {
  background-color: #002856;
}

.deptTitle {
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
}

input[disabled] {
  opacity: 0.7;
}

.livedName-box {
  background-color: #d9eff4;
  padding: 1.5em;
  max-width: 700px;
  margin: 3em 0;
}

.privateAccount-box {
  background-color: #edd480;
  padding: 2em;
  max-width: 700px;
  font-weight: 700;
  font-size: 0.9em;
  margin: 3em 0;
}

.Button--link {
  height: 2.3em;
  padding: 8px 1.7em;
  text-decoration: none;
  text-align: center;
}

.livedName-split {
  display: grid;
  grid-template-columns: auto 12em;
}

.livedName-Label {
  font-weight: 700;
  margin: auto 0;
}

.livedName-split-2 {
  display: grid;
  grid-template-columns: auto auto;
}

.livedName-split-3 {
  display: grid;
  grid-template-columns: 11em auto auto;
}

@media screen and (max-width: 700px) {
  .livedName-split-2 {
    display: grid;
    grid-template-columns: auto;
  }

  .livedName-split-3 {
    display: grid;
    grid-template-columns: auto;
  }
}
.livedName-text {
  margin: auto 0;
}

button[type="button"][disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

a,
a:hover,
a:active {
  cursor: pointer;
}
